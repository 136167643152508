<template>
  <div class="mod-config">
    <!----------------------------------------- 主列表 --------------------------------------->
    <!---------------页眉相关 ----------------->
    <Row style="margin-top: 10px">
      <Col span="6" style="padding: 0 5px">
        <Card>
          <div
                  style="
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 10px;
        "
          >
            <div style="display: flex">
              <div style="padding-left: 20px;font-size: 18px;font-weight: bold">
                <span>老人年龄结构信息</span>
              </div>
            </div>
            <div>
              <Button
                      type="primary"
                      @click="getFunAge"
                      icon="md-search"
                      style="margin-right: 5px"
              >查询</Button
              >
            </div>
          </div>

          <!---------------页眉相关 END----------------->
          <!---------------表格相关 ----------------->
          <Table
                  ref="orderManAgeTable"
                  border
                  :columns="dataMain.oldManColumns"
                  :data="dataMain.orderManAgeData"
                  size="small"
                  :height="tableHeight"
                  :width="tableWidth"
                  highlight-row
                  tooltip-theme="light"
                  :loading="AgeLoading"
          >
            <template slot="action"  slot-scope="props">
              <Input v-model="props.row.value"
                      :active-change="false"
                      @on-blur="oldManSave('老人年龄结构',props)"
              />
            </template>
          </Table>
          <!---------------表格相关 END----------------->
        </Card>

      </Col>
      <Col span="6" style="padding: 0 5px">
        <Card>
          <div
                  style="
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 10px;
        "
          >
            <div style="display: flex">
              <div style="padding-left: 20px;font-size: 18px;font-weight: bold">
                <span>老人能力结构信息</span>
              </div>
            </div>
            <div>
              <Button
                      type="primary"
                      @click="getFunNl"
                      icon="md-search"
                      style="margin-right: 5px"
              >查询</Button
              >
            </div>
          </div>

          <!---------------页眉相关 END----------------->
          <!---------------表格相关 ----------------->
          <Table
                  ref="orderManAgeTable"
                  border
                  :columns="dataMain.oldNlColumns"
                  :data="dataMain.orderManNlData"
                  size="small"
                  :height="tableHeight"
                  :width="tableWidth"
                  highlight-row
                  tooltip-theme="light"
                  :loading="tableJgLoading"
          >
            <template slot="action"  slot-scope="props">
              <Input v-model="props.row.value"
                     :active-change="false"
                     @on-blur="oldManSave('老人能力结构',props)"
              />
            </template>
          </Table>
          <!---------------表格相关 END----------------->
        </Card>

      </Col>
      <Col span="6" style="padding: 0 5px">
        <Card>
          <div
                  style="
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 10px;
        "
          >
            <div style="display: flex">
              <div style="padding-left: 20px;font-size: 18px;font-weight: bold">
                <span>老人居住环境结构信息</span>
              </div>
            </div>
            <div>
              <Button
                      type="primary"
                      @click="getFunjzhj"
                      icon="md-search"
                      style="margin-right: 5px"
              >查询</Button
              >
            </div>
          </div>

          <!---------------页眉相关 END----------------->
          <!---------------表格相关 ----------------->
          <Table
                  ref="orderManAgeTable"
                  border
                  :columns="dataMain.oldjzhjColumns"
                  :data="dataMain.orderManjzhjData"
                  size="small"
                  :height="tableHeight"
                  :width="tableWidth"
                  highlight-row
                  tooltip-theme="light"
                  :loading="jzhjLoading"
          >
            <template slot="action"  slot-scope="props">
              <Input v-model="props.row.value"
                     :active-change="false"
                     @on-blur="oldManSave('老人居住环境结构',props)"
              />
            </template>
          </Table>
          <!---------------表格相关 END----------------->
        </Card>

      </Col>
      <Col span="6" style="padding: 0 5px">
        <Card>
          <div
                  style="
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 10px;
        "
          >
            <div style="display: flex">
              <div style="padding-left: 20px;font-size: 18px;font-weight: bold">
                <span>服务人员结构信息-人社</span>
              </div>
            </div>
            <div>
              <Button
                      type="primary"
                      @click="getFunRs"
                      icon="md-search"
                      style="margin-right: 5px"
              >查询</Button
              >
            </div>
          </div>

          <!---------------页眉相关 END----------------->
          <!---------------表格相关 ----------------->
          <Table
                  ref="orderManAgeTable"
                  border
                  :columns="dataMain.fwryRsColumns"
                  :data="dataMain.fwryRsData"
                  size="small"
                  :height="tableHeight"
                  :width="tableWidth"
                  highlight-row
                  tooltip-theme="light"
                  :loading="RstableLoading"
          >
            <template slot="action"  slot-scope="props">
              <Input v-model="props.row.value"
                     :active-change="false"
                     @on-blur="oldManSave('服务人员结构信息-人社',props)"
              />
            </template>
          </Table>
          <!---------------表格相关 END----------------->
        </Card>

      </Col>
    </Row>
    <Row style="margin-top: 10px">
      <Col span="6" style="padding: 0 5px">
        <Card>
          <div
                  style="
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 10px;
        "
          >
            <div style="display: flex">
              <div style="padding-left: 20px;font-size: 18px;font-weight: bold">
                <span>服务人员结构信息-机构</span>
              </div>
            </div>
            <div>
              <Button
                      type="primary"
                      @click="getFunFwryJg"
                      icon="md-search"
                      style="margin-right: 5px"
              >查询</Button
              >
            </div>
          </div>

          <!---------------页眉相关 END----------------->
          <!---------------表格相关 ----------------->
          <Table
                  ref="orderManAgeTable"
                  border
                  :columns="dataMain.fwJgRsColumns"
                  :data="dataMain.fwJgRsData"
                  size="small"
                  :height="tableHeight"
                  :width="tableWidth"
                  highlight-row
                  tooltip-theme="light"
                  :loading="fwJgRstableLoading"
          >
            <template slot="action"  slot-scope="props">
              <Input v-model="props.row.value"
                     :active-change="false"
                     @on-blur="oldManSave('服务人员结构信息-机构',props)"
              />
            </template>
          </Table>
          <!---------------表格相关 END----------------->
        </Card>

      </Col>
      <Col span="12" style="padding: 0 5px">
        <Card>
          <div
                  style="
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 10px;
        "
          >
            <div style="display: flex">
              <div style="padding-left: 20px;font-size: 18px;font-weight: bold">
                <span>区县服务结构信息</span>
              </div>
            </div>
            <div>
              <Button
                      type="primary"
                      @click="getFunQxfwjg"
                      icon="md-search"
                      style="margin-right: 5px"
              >查询</Button
              >
            </div>
          </div>
          <!---------------页眉相关 END----------------->
          <!---------------表格相关 ----------------->
          <Table
                  ref="orderManAgeTable"
                  border
                  :columns="dataMain.qxfwjgColumns"
                  :data="dataMain.qxfwjgData"
                  size="small"
                  :height="tableHeight"
                  :width="tableWidth"
                  highlight-row
                  tooltip-theme="light"
                  :loading="qxfwjgtableLoading"
          >
            <template slot="bdrs"  slot-scope="props">
              <Input v-model="props.row.bdrs"
                     :active-change="false"
                     @on-blur="oldManSave('区县服务结构信息',props)"
              />
            </template>
            <template slot="hlz"  slot-scope="props">
              <Input v-model="props.row.hlz"
                     :active-change="false"
                     @on-blur="oldManSave('区县服务结构信息',props)"
              />
            </template>
            <template slot="rjzl"  slot-scope="props">
              <Input v-model="props.row.rjzl"
                     :active-change="false"
                     @on-blur="oldManSave('区县服务结构信息',props)"
              />
            </template>
            <template slot="wgy"  slot-scope="props">
              <Input v-model="props.row.wgy"
                     :active-change="false"
                     @on-blur="oldManSave('区县服务结构信息',props)"
              />
            </template>
            <template slot="yizhan"  slot-scope="props">
              <Input v-model="props.row.yizhan"
                     :active-change="false"
                     @on-blur="oldManSave('区县服务结构信息',props)"
              />
            </template>
            <template slot="yly"  slot-scope="props">
              <Input v-model="props.row.yly"
                     :active-change="false"
                     @on-blur="oldManSave('区县服务结构信息',props)"
              />
            </template>
          </Table>
          <!---------------表格相关 END----------------->
        </Card>

      </Col>
      <Col span="6" style="padding: 0 5px">
        <Card>
          <div
                  style="
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 10px;
        "
          >
            <div style="display: flex">
              <div style="padding-left: 20px;font-size: 18px;font-weight: bold">
                <span>服务项目收费信息</span>
              </div>
            </div>
            <div>
              <Button
                      type="primary"
                      @click="getFunFwxmsf"
                      icon="md-search"
                      style="margin-right: 5px"
              >查询</Button
              >
            </div>
          </div>

          <!---------------页眉相关 END----------------->
          <!---------------表格相关 ----------------->
          <Table
                  ref="orderManAgeTable"
                  border
                  :columns="dataMain.fwxmsfColumns"
                  :data="dataMain.fwxmsfData"
                  size="small"
                  :height="tableHeight"
                  :width="tableWidth"
                  highlight-row
                  tooltip-theme="light"
                  :loading="FwxmsftableLoading"
          >
            <template slot="op"  slot-scope="props">
              <Input v-model="props.row.op"
                     :active-change="false"
                     @on-blur="oldManSave('服务项目收费信息',props)"
              />
            </template>
            <template slot="hm"  slot-scope="props">
              <Input v-model="props.row.hm"
                     :active-change="false"
                     @on-blur="oldManSave('服务项目收费信息',props)"
              />
            </template>
          </Table>
          <!---------------表格相关 END----------------->
        </Card>

      </Col>
    </Row>
    <!----------------------------------------- 主列表 END--------------------------------------->
  </div>
</template>
<!--大屏数据维护-->
<script>
  import {
    getOneRightOneListPage,
    getOneLeftOneOneList,
    getOneLeftOneTwoList,
    getOneLeftOneThreeList,
    getOneLeftTwoOneList,
    getOneLeftTwoTwoList,
    getOneLiftThreeList,
    getOneRightTwoList,
    updateOneLeftOneOne,
    updateOneLeftOneTwo,
    updateOneLeftOneThree,
    updateOneLeftTwoOne,
    updateOneLeftTwoTwo,
    updateOneLiftThree,
    updateOneRightTwo,
  } from "./dataMaintenance";

//-------------------新增修改用---------------------
const classroomVO = {
  //
  id: "",
  code: "",
  name: "",
  address: "",
  orgId: "",
  status: "",
  isDeleted: "",
  creator: "",
  createDate: "",
  updator: "",
  updateDate: "",
};
//-------------------新增修改用 END---------------------

//todo
export default {
  name: "dataMaintenance",
  data() {
    return {
      //--------------------------主页面表格用--------------------------
      tableHeight: 0,
      tableWidth: "",
      dataMain: {
        orderManAgeData:[],
        orderManNlData:[],
        orderManjzhjData:[],
        fwryRsData:[],//服务人员结构信息人社
        fwJgRsData:[],//服务人员结构信息机构
        qxfwjgData:[],//区县服务结构信息
        fwxmsfData:[],//服务项目收费信息
        oldManColumns: [
          { title: "序号", type: "index", width: 70, align: "center" },
          { title: "类型", key: "name", minWidth: 70, align: "center" },
          {title: "人数", slot: 'action', align: "center", minWidth: 70,},
        ],
        oldNlColumns: [
          { title: "序号", type: "index", width: 70, align: "center" },
          { title: "能力类型", key: "name", minWidth: 70, align: "center" },
          {title: "人数", slot: 'action', align: "center", minWidth: 70,},
        ],
        oldjzhjColumns: [
          { title: "序号", type: "index", width: 70, align: "center" },
          { title: "环境类型", key: "name", minWidth: 70, align: "center" },
          {title: "人数", slot: 'action', align: "center", minWidth: 70,},
        ],
        fwryRsColumns: [
          { title: "序号", type: "index", width: 70, align: "center" },
          { title: "人员类型", key: "name", minWidth: 70, align: "center" },
          {title: "人数", slot: 'action', align: "center", minWidth: 70,},
        ],
        fwJgRsColumns: [
          { title: "序号", type: "index", width: 70, align: "center" },
          { title: "人员类型", key: "name", minWidth: 70, align: "center" },
          {title: "人数", slot: 'action', align: "center", minWidth: 70,},
        ],
        qxfwjgColumns: [
          { title: "序号", type: "index", width: 70, align: "center" },
          { title: "区县", key: "area", minWidth: 70, align: "center" },
          {title: "养老院", slot: 'yly', align: "center", minWidth: 70,},
          {title: "日间照料", slot: 'rjzl', align: "center", minWidth: 70,},
          {title: "驿站", slot: 'yizhan', align: "center", minWidth: 70,},
          {title: "护理站", slot: 'hlz', align: "center", minWidth: 70,},
          {title: "网格员", slot: 'wgy', align: "center", minWidth: 70,},
          {title: "绑定人数", slot: 'bdrs', align: "center", minWidth: 70,},
        ],
        fwxmsfColumns: [
          { title: "序号", type: "index", width: 70, align: "center" },
          { title: "项目", key: "item", minWidth: 70, align: "center" },
          {title: "机构评估费/次", slot: 'op', align: "center", minWidth: 70,},
          {title: "居家评估费/次", slot: 'hm', align: "center", minWidth: 70,},
        ],

      },
      //--------------------------主页面表格用 END--------------------------
      AgeLoading: false,
      tableJgLoading: false,
      jzhjLoading: false,
      RstableLoading: false,
      fwJgRstableLoading: false,
      qxfwjgtableLoading: false,
      FwxmsftableLoading: false,
    };
  },
  methods: {
    //老人结构年龄
    getFunAge(){
      this.AgeLoading = true
      getOneLeftOneOneList().then(res=>{
        this.AgeLoading = false
        this.dataMain.orderManAgeData = res;
      })
    },
    //老人结构能力
    getFunNl(){
      this.tableJgLoading = true
      getOneLeftOneTwoList().then(res=>{
        this.tableJgLoading = false
        this.dataMain.orderManNlData = res;
      })
    },
    //老人居住环境
    getFunjzhj(){
      this.jzhjLoading = true
      getOneLeftOneThreeList().then(res=>{
        this.jzhjLoading = false
        this.dataMain.orderManjzhjData = res;
      })
    },
    //服务人员结构信息-人社
    getFunRs(){
      this.RstableLoading=true
      getOneLeftTwoOneList().then(res=>{
        this.RstableLoading=false
        this.dataMain.fwryRsData = res;
      })
    },
    //区县服务结构信息
    getFunFwryJg(){
      this.fwJgRstableLoading = true;
      getOneLeftTwoTwoList().then(res=>{
        this.fwJgRstableLoading = false;
        this.dataMain.fwJgRsData = res;
      })
    },
    //区县服务结构信息
    getFunQxfwjg(){
      this.qxfwjgtableLoading = true;
      getOneLiftThreeList().then(res=>{
        this.qxfwjgtableLoading = false;
        this.dataMain.qxfwjgData = res;
      })
    },
    //服务项目收费信息
    getFunFwxmsf(){
      this.FwxmsftableLoading = true
      getOneRightTwoList().then(res=>{
        this.FwxmsftableLoading = false
        this.dataMain.fwxmsfData = res;
      })
    },
    oldManSave(type,props) {
      let data = {
        "name": props.row.name,
        "value": props.row.value,
      }
      if(type == '老人年龄结构'){
        updateOneLeftOneOne(data).then(res =>{
          if(res.code == 0){
            this.$Message.success("修改成功");
            this.getFunAge()
          }else{
            this.$Message.error("修改失败");
          }
        })
      }
      if(type == '老人能力结构'){
        updateOneLeftOneTwo(data).then(res =>{
          if(res.code == 0){
            this.$Message.success("修改成功");
            this.getFunNl()
          }else{
            this.$Message.error("修改失败");
          }
        })
      }
      if(type == '老人居住环境结构'){
        updateOneLeftOneThree(data).then(res =>{
          if(res.code == 0){
            this.$Message.success("修改成功");
            this.getFunjzhj()
          }else{
            this.$Message.error("修改失败");
          }
        })
      }
      if(type == '服务人员结构信息-人社'){
        updateOneLeftTwoOne(data).then(res =>{
          if(res.code == 0){
            this.$Message.success("修改成功");
            this.getFunRs()
          }else{
            this.$Message.error("修改失败");
          }
        })
      }
      if(type == '服务人员结构信息-机构'){
        updateOneLeftTwoTwo(data).then(res =>{
          if(res.code == 0){
            this.$Message.success("修改成功");
            this.getFunFwryJg()
          }else{
            this.$Message.error("修改失败");
          }
        })
      }
      if(type == '区县服务结构信息'){
        let obj = props.row
        updateOneLiftThree(obj).then(res =>{
          if(res.code == 0){
            this.$Message.success("修改成功");
            this.getFunQxfwjg()
          }else{
            this.$Message.error("修改失败");
          }
        })
      }
      if(type == '服务项目收费信息'){
        let obj = props.row
        updateOneRightTwo(obj).then(res =>{
          if(res.code == 0){
            this.$Message.success("修改成功");
            this.getFunFwxmsf()
          }else{
            this.$Message.error("修改失败");
          }
        })
      }
    },
  },
  //todo
  //---------------------初始化加载 ----------------------
  mounted() {
    this.tableHeight = window.innerHeight * 0.5 - 110;
    window.onresize = () => {
      return (() => {
        this.tableHeight = window.innerHeight * 0.5 - 110;
      })();
    };
    this.getFunAge();
    this.getFunNl();
    this.getFunjzhj();
    this.getFunRs();
    this.getFunFwryJg();
    this.getFunQxfwjg();
    this.getFunFwxmsf();
  },
  //---------------------初始化加载 END----------------------
};
</script>

<style scoped>
</style>
